export default function arraysEqual<T>(a: Array<T>, b: Array<T>, ignoreOrder = false) {
  if (a === b) return true
  if (a == null || b == null) return false
  if (a.length !== b.length) return false

  let sortedA = a
  let sortedB = b
  if (ignoreOrder) {
    sortedA = [...a].sort()
    sortedB = [...b].sort()
  }

  for (let i = 0; i < sortedA.length; ++i) {
    if (sortedA[i] !== sortedB[i]) return false
  }
  return true
}
