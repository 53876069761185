import { useMemo } from 'react'

import { useAppSelector } from 'hooks/reduxHooks'
import getOfferListKey from 'lib/offer/offerListKey'

export interface Options {
  disabled?: boolean;
}

function useStreamEnded(
  filters: App.OfferListFilters | undefined,
  options: Options = {},
): boolean {
  const disabled = options.disabled || !filters

  const listKey = useMemo(() => {
    if (filters) {
      return getOfferListKey(filters)
    }
    return ''
  }, [filters])

  const streamEnd = useAppSelector(state => state.offer.offerLists[listKey]?.streamEnded) ?? false

  if (disabled) {
    return false
  }

  return streamEnd
}

export default useStreamEnded
