import OfferSortControl from 'components/Common/OfferSortControl/OfferSortControl'
import TextButton from 'components/Luxkit/Button/TextButton'
import VerticalSpacer from '../../Common/Spacing/VerticalSpacer'
import LineMapIcon from 'components/Luxkit/Icons/line/LineMapIcon'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { OfferListSortOption } from 'constants/offerListFilters'
import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import AssistChip from 'components/Luxkit/Chips/AssistChip'
import LineFilterAltIcon from 'components/Luxkit/Icons/line/LineFilterAltIcon'
import OfferListFilterSidebar from 'components/OfferList/OfferListFilter/OfferListFilterSidebar'
import { connect } from 'react-redux'
import MobileOfferListFilters from 'components/OfferList/OfferListFilter/MobileOfferListFilters'
import noop from 'lib/function/noop'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import config from 'constants/config'
import { searchSwitchViewClick } from 'analytics/eventDefinitions'
import { addGTMEvent } from 'api/googleTagManager'
import { pluralizeToString } from 'lib/string/pluralize'
import getOfferListKey from 'lib/offer/offerListKey'
import { EnabledFilters } from 'components/Search/type'
import HotelBroadSearchLocation from './HotelSearchBroadSearchLocation'
import useSlimMobileSearch from 'components/SearchV2/Hooks/useSlimMobileSearch'
import useGlobalSearchURLHashFilter from 'hooks/GlobalSearch/useGlobalSearchURLHashFilter'

const Container = styled(CSSBreakpoint)`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const MobileContainer = styled(CSSBreakpoint)`
  width: 100%;
`

const FilterAndCountContainer = styled.div`
  display: flex;
  align-items: center;
  > * + * {
    margin-left: ${rem(16)};
  }
`

const ButtonContainer = styled.div`
  display: flex;
  > * + * {
    margin-left: ${rem(8)};
  }
`

function handleClick() {
  addGTMEvent(searchSwitchViewClick())
}
interface MappedStateProps {
  availableFilters?: App.OfferListFilterOptions;
}

interface Props {
  mainListTotal: number;
  sortOptions: Array<OfferListSortOption>;
  mapViewUrl?: string;
  isFlexible?: boolean
  shouldShowFilters?: boolean
  filters: App.OfferListFilters;
  windowSearch: string;
  showOfferCount?: boolean;
  showHolidayTypes?: boolean;
  searchType?: string
  enabledFilters?: EnabledFilters;
  fetching?: boolean;
  countTextOverride?: string;
  specificPlaces?: Array<App.SpecificPlace>;
  destinationName?: string;
}

function OfferCountAndSort(props: Props & MappedStateProps) {
  const {
    mainListTotal,
    sortOptions,
    mapViewUrl,
    isFlexible,
    shouldShowFilters = false,
    showOfferCount = true,
    filters,
    windowSearch,
    searchType,
    enabledFilters,
    fetching,
    countTextOverride,
    specificPlaces,
    destinationName,
  } = props
  const propertySearch = searchType === 'property'
  const countText = (() => {
    if (countTextOverride) {
      return countTextOverride
    }
    if (propertySearch) {
      return ''
    }
    return isFlexible ? `Showing ${pluralizeToString('offer', mainListTotal)} with cheapest availabilities for your dates` : `Showing ${pluralizeToString('offer', mainListTotal)}`
  })()

  const { isFilterPanelOpen, toggleFilterPanel } = useGlobalSearchURLHashFilter()

  const showSlimMobileSearch = useSlimMobileSearch()

  return (<>
    <div>
      {!showSlimMobileSearch && <MobileContainer max="mobile">
        <MobileOfferListFilters
          mapViewPath={mapViewUrl}
          onSortAndFilterClick={toggleFilterPanel}
          onViewSwitch={noop}
          hasSortOptions
          showFilterIcon={false}
        />
      </MobileContainer>}
      <VerticalSpacer gap={16}>
        {!!specificPlaces?.length && !!destinationName && <HotelBroadSearchLocation specificPlaces={specificPlaces} destinationName={destinationName} />}
        <MobileContainer max="mobile">
          {showOfferCount && !fetching && <BodyText variant="medium">{countText}</BodyText>}
        </MobileContainer>
        <Container min="tablet">
          <FilterAndCountContainer>
            {shouldShowFilters && <AssistChip size="medium" variant="filled" startIcon={<LineFilterAltIcon />} onClick={toggleFilterPanel}>
              Filters
            </AssistChip>}
            {showOfferCount && !fetching && <BodyText variant="large">{countText}</BodyText>}
          </FilterAndCountContainer>
          <ButtonContainer>
            <OfferSortControl sortOptions={sortOptions} />
            {!config.businessTraveller.isEnabled && mapViewUrl &&
              <TextButton
                kind="secondary"
                variant="dark"
                to={mapViewUrl}
                startIcon={<LineMapIcon />}
                data-testid="map-view-button"
                onClick={handleClick}
              >
                Map view
              </TextButton>
            }
          </ButtonContainer>
        </Container>
      </VerticalSpacer>
    </div>
    {!!enabledFilters && <OfferListFilterSidebar
      open={isFilterPanelOpen}
      onClose={toggleFilterPanel}
      filters={filters}
      sortOptions={sortOptions}
      search={windowSearch}
      fetching={!!fetching}
      enabledFilters={enabledFilters}
    />}
  </>)
}

export default connect<MappedStateProps, undefined, Props, App.State>((appState, ownProps) => {
  const offerListKey = getOfferListKey(ownProps.filters)

  return {
    availableFilters: appState.offer.offerListFilterOptions[offerListKey],
  }
})(OfferCountAndSort)
