import React, { useEffect } from 'react'

import { useInView } from 'react-intersection-observer'
import noop from 'lib/function/noop'
import TextButton from 'components/Luxkit/Button/TextButton'
import OfferListLoadingOfferTile from 'components/OfferList/OfferListTiles/OfferListLoadingOfferTile'
import { fillArray } from 'lib/array/arrayUtils'
import Typography from 'components/Luxkit/Typography/Typography'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import styled from 'styled-components'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { rem } from 'polished'

const LoaderGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(0)};

  ${mediaQueryUp.tablet} {
    gap: ${rem(16)};
  }
`

const SCROLL_NEAR_THRESHOLD = 500

interface Props extends Omit<React.ComponentProps<typeof TextButton>, 'kind'> {
  onScrollNear?: () => void;
  showLoadingTiles?: boolean;
}

function OfferListLoadMoreButton(props: Props) {
  const { children, onScrollNear = noop, showLoadingTiles = false, ...buttonProps } = props

  const [buttonRef, isInView] = useInView({
    rootMargin: `${SCROLL_NEAR_THRESHOLD}px`,
  })

  useEffect(() => {
    if (isInView) {
      onScrollNear()
    }
    // eslint-disable-next-line
  }, [isInView])

  return (
    <VerticalSpacer gap={16} ref={buttonRef}>
      {showLoadingTiles && (
        <LoaderGrid>
          {fillArray(4).map((_, index) => (
            <OfferListLoadingOfferTile
              key={index}
              tileStyle="search"
              className="offer-tile"
            />
          ))}
        </LoaderGrid>
      )}
      <Typography align="center">
        <TextButton kind="primary" {...buttonProps}>
          {children}
        </TextButton>
      </Typography>
    </VerticalSpacer>
  )
}

OfferListLoadMoreButton.defaultProps = {
  onScrollNear: noop,
}

export default OfferListLoadMoreButton
